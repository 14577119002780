<template>
  <div v-show="!isHidden" :style="CSS + ';height: ' + height" :class="CSSClasses">
    <registry-simple
      v-loading="!getRegistryRecordId() && !isEditor()"
      :element-loading-text="$locale.registry.not_available"
      element-loading-spinner="el"
      element-loading-background="rgb(244, 244, 245, 0.8)"
      :pageLimit="pageLimit"
      :showVerticalLine="showVerticalLine"
      :show-search="showSearch"
      :searchAttributes="searchAttributes"
      :showButton="showButton"
      :default-group="defaultGroup"
      :readonly="_isReadonly"
      @edit-record="editRecord"
      @open-card="openCard"
      :mini="true"
      :headers="headers"
      ref="registry"
      :id="id"
      :outer-xref="outerXref"
      :name="name"
      :styleTableHeader="styleTableHeader"
      :treeTable="treeTable"
      :table-add-existing="tableAddExisting"
      :ignore-fast-card="!!(defaultCardId && defaultCardId.cardId)"
      :defaults="defaults"
    >
    </registry-simple>
    <slot></slot>
  </div>
</template>

<script>
import RegistrySimple from '@/components/Registry/RegistryTable.vue'
import { eventBus } from '@/eventBus'
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import RegistryCard from '@/components/RegistryCard'
import FilterBuilder, { EComponentTypes } from '../utils'
import ActionExecutor from '@/core/infrastructure/service/ActionExecutor'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'

export default {
  name: 'xref_outer_field',
  inject: {
    getRegistryRecordId: {
      default: () => () => null
    },
    openRegistryCard: {
      default: () => () => null
    },
    isEditor: {
      default: () => false
    },
    getRegistryId: {
      default: () => () => null
    },
    openDashboardCard: {
      default: () => {}
    },
    getEventBus: {
      default: () => () => {
        return {
          $emit: () => {},
          $on: () => {}
        }
      }
    }
  },
  mixins: [mixin, registryMixin, refreshComponentsMixin],
  components: {
    RegistrySimple,
    RegistryCard
  },
  props: {
    heightTable: {
      type: String,
      description: 'Высота таблицы',
      default: '100%'
    },
    styleTableHeader: {
      type: String,
      description: 'CSS стили шапки таблицы',
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    defaultGroup: {
      description: 'Группировка (attr_N_)',
      type: String,
      options: {
        removeSpaces: true
      }
    },
    label: {
      description: 'Название',
      type: String
    },
    pageLimit: {
      type: Number,
      description: 'Количество записей на странице'
    },
    registry_properties: {
      type: Array,
      frozen: true
    },
    extendedToBottom: {
      type: Boolean,
      description: 'Растягивать вниз'
    },
    headers: {
      type: Array,
      editor: 'RegistryHeaders',
      description: 'Столбцы'
    },
    optimizeOptions: {
      type: Object,
      default: () => ({}),
      editor: 'OptimizeColumnsLoad'
    },
    showSearch: {
      type: Boolean,
      description: 'Показывать поиск'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    treeTable: {
      type: Boolean,
      description: 'Древовидная таблица',
      default: false
    },
    searchAttributes: {
      type: String,
      description: 'Поиск по',
      options: {
        tooltip: {
          show: true,
          content: 'Например attr_N_,attr_N_ '
        }
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    showButton: {
      type: Object,
      default: () => {
        return {
          update: false,
          add: false,
          add_existing: false,
          delete: false,
          export: false,
          import: false,
          views: false,
          group: false,
          filter: false,
          edit: false
        }
      },
      editor: 'ShowButton',
      description: 'Видимость кнопок'
    },
    showVerticalLine: {
      type: Boolean,
      description: 'Убрать вертикальные линии'
    },
    defaultCardId: {
      type: [Object, Number],
      description: 'Карточка',
      editor: 'Cards',
      default: () => {
        return {
          cardId: null,
          isWindow: false,
          windowTitle: '',
          windowWidth: '25'
        }
      }
    },
    defaults: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        showXrefOption: false,
        showEqualsTypes: false
      }
    },
    tableAddExisting: {
      type: Object,
      default: () => ({}),
      editor: 'TableAddExisting'
    }
  },
  data () {
    return {
      id: null,
      outerXrefId: null,
      card: null,
      multi: false,
      rendered: false,
      observer: undefined
    }
  },
  provide () {
    return {
      xrefOuterFieldScope: this
    }
  },
  computed: {
    height () {
      if (this.extendedToBottom && !this.isHidden && this.rendered && this.$el.offsetTop) {
        return 'calc(100% - ' + this.$el.offsetTop + 'px)'
      }
      return this.heightTable
    },
    outerXref () {
      return {
        id: this.outerXrefId,
        value: this.getRegistryRecordId(),
        isMulti: this.multi,
        is: true
      }
    },
    dataFilters () {
      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = 'eq'
      //     if (item.isXref) {
      //       type = 'eqx'
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         if (type === 'eqx' && parseInt(this.getModel()[item.attribute]) !== 0) {
      //           filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //         }
      //         if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
      //           filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //         }
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     }
      //   })
      // }
      // return filters

      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.xrefOuterField
      )

      return builder.buildAsQueryParams()
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    headers () {
      this.addOptimizeAttribute()
    },
    optimizeOptions: {
      deep: true,
      handler () {
        this.addOptimizeAttribute()
      }
    }
  },
  created () {
    this.parseColumnHiddenConditions(this.headers)
  },
  mounted () {
    this.$nextTick(() => {
      let me = this
      let respondToVisibility = function (element, callback) {
        let options = {
          root: document.documentElement
        }

        me.observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0)
          })
        }, options)

        me.observer.observe(element)
      }
      respondToVisibility(this.$el, (visible) => {
        if (visible) {
          if (this.$refs.registry) {
            setTimeout(() => {
              if (this.$refs.registry && this.$refs.registry.$refs.table) {
                this.$refs.registry.$refs.table.doLayout()
              }
            }, 100
            )
          }
        }
      })
      setTimeout(() => { this.rendered = true }, 100)
      if (this.$refs.registry && this.$refs.registry.$refs.table) {
        this.$refs.registry.$refs.table.doLayout()
      }
    })

    let xref = this.registry_properties.find((item) => item.id === 'xref')
    if (!xref || !xref.value || this.isEditor()) {
      return false
    }
    let me = this
    this.$http.get(`${this.$config.api}/objecteditor/entities/${xref.value}`)
      .then((response) => {
        let xrefObjectId = response.data.data.object_id
        if (response.data.data.entity_type_id === 'xref_multi_field') {
          this.multi = true
        }
        if (xrefObjectId) {
          me.id = xrefObjectId
          me.outerXrefId = xref.value
          /* me.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${me.id}`).then((response) => {
            let card = response.data.filter(item => item.is_default)
            me.card = card[0]
          }) */
        }
      })
    if (this.$refs.registry) {
      this.dataFilters.forEach((item) => {
        this.$refs.registry.addFilter(item)
      })
    }
    this.getEventBus().$on('registry-card-saved', this.loadData)
  },
  beforeDestroy () {
    if (this.observer) {
      this.observer.unobserve(this.$el)
    }
    this.getEventBus().$off('registry-card-saved', this.loadData)
  },
  methods: {
    addOptimizeAttribute () {
      if (this.optimizeOptions.showOptimizeOptions === true) {
        let optimizeAttrs = ''
        if (typeof this.headers !== 'undefined') {
          this.headers.forEach((item) => {
            switch (item.type) {
              case 'htmlField':
                if (item.hasOwnProperty('htmlTemplate')) {
                  optimizeAttrs += item.htmlTemplate.match(/attr_[0-9]+_/gi) + ','
                }
                break
              case 'fieldGroup':
                item.children.forEach((children) => {
                  optimizeAttrs += this.fieldGroupAttrsParse(children)
                })
                break
              default:
                if (item.field.match(/attr_[0-9]+_/gi) !== null) {
                  optimizeAttrs += item.value + ','
                }
            }

            if (item.clickType === 'open_card') {
              optimizeAttrs += `attr_${item.card.fieldId}_,`
            }
          })
          this.$set(this.optimizeOptions, 'value', [...new Set(optimizeAttrs.slice(0, -1).split(','))].toString())
        }
      } else {
        this.$set(this.optimizeOptions, 'value', null)
      }
    },
    fieldGroupAttrsParse (item) {
      let optimizeAttrs = ''
      if (item.type === 'fieldGroup') {
        item.children.forEach((children) => {
          optimizeAttrs += this.fieldGroupAttrsParse(children)
        })
      } else {
        optimizeAttrs += item.value + ','
      }

      return optimizeAttrs
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    loadData () {
      if (!this.$refs.registry || this.isEditor()) {
        return false
      }
      this.$refs.registry.clearFilters()
      this.dataFilters.forEach((item) => {
        this.$refs.registry.addFilter(item)
      })
      this.$refs.registry.loadData()
    },
    parseColumnHiddenConditions (columns) {
      if (!Array.isArray(columns)) {
        return
      }

      columns.forEach((column) => {
        if ((column.hidden_condition || '').trim().length > 0) {
          let condition = JSON.parse(JSON.stringify(column.hidden_condition))
          let attributes = condition.match(/\{(.*?)\}/g)
          attributes.forEach((attribute) => {
            attribute = attribute.replace('{', '').replace('}', '')
            let value = this.getModel()[attribute]
            try {
              value = JSON.parse(value)
            } catch (e) {

            }
            if (value instanceof Array) {
              value = value.map(item => item.id || item).join(',')
            }
            condition = condition.replace(`{${attribute}}`, value)
          })
          try {
            if (eval(condition)) {
              column.hidden = true
            } else {
              column.hidden = false
            }
          } catch (e) {
            console.info(`invalid condition: ${column.hidden_condition}, result: ${condition}`)
          }
        }
        if ((column.children || []).length > 0) {
          this.parseColumnHiddenConditions(column.children)
        }
        if ((column.children || []).length > 0 && (column.children || []).filter(item => item.hidden).length === (column.children || []).length) {
          column.hidden = true
        }
      })
    },
    async getCardId (recordId) {
      let url = `${this.$config.api}/registryservice/registry/${this.id}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.id}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)

      return data.data[0]
    },
    async editRecord (data) {
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = await this.getCardId(data.id)
      }
      let initialData = {}
      if (parseInt(this.getRegistryId()) !== parseInt(this.id)) {
        initialData = JSON.parse(JSON.stringify(this.getModel()))
      }
      this.openRegistryCard({
        registryId: this.id,
        cardId: card.id,
        cardName: card.name,
        recordId: data.id,
        initialData: initialData,
        registry: this.$refs.registry
      })
    },
    async openCard () {
      if (!this.getRegistryRecordId()) {
        return false
      }
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = await this.getCardId()
      }
      if (!card) {
        return false
      }
      let initialData = {}
      if (this.getRegistryId() !== this.id) {
        initialData = JSON.parse(JSON.stringify(this.getModel()))
      }
      // getRegistryRecordId - получить id реестра в котором находимся
      // outerXrefId - id ссылки на которую ссылается вн. ссылка
      initialData[`attr_${this.outerXrefId}_`] = this.getRegistryRecordId()
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (this.defaultCardId.isWindow) {
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (this.defaultCardId.windowWidth) {
          customClass += `dashboard_window_width_${this.defaultCardId.windowWidth}`
        }
        let me = this
        this.$msgbox({
          title: me.defaultCardId.windowTitle,
          customClass: customClass,
          message: h('registry-card', {
            style: {
              height: me.defaultCardId.windowHeight || ''
            },
            props: {
              cardId: card.id,
              registryId: me.id,
              recordId: null,
              initialData: initialData,
              context: me
            },
            on: {
              cancelChanges: function () {
                me.$msgbox.close()
              }
            },
            key: this.generateGuid()
          }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      } else {
        this.openRegistryCard({
          registryId: this.id,
          cardId: card.id,
          cardName: card.name,
          recordId: null,
          initialData: initialData,
          registry: this.$refs.registry
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
